import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { kudconnectApi } from '../misc/KudconnectApi';
import { Container, Segment, Grid, Form, Input, Button } from 'semantic-ui-react';
import ErrorModal from '../misc/ErrorModal';


function ApplicationDetail() {
    // useLocation hook from react-router-dom provides access to the state we passed during navigation
    const location = useLocation();
    const history = useHistory();
    const { applicationId } = useParams();
    const isNewApplication = applicationId === 'new';
    const { keycloak } = useKeycloak();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [baseUrl, setbaseUrl] = useState('');
    const [authClientId, setAuthClientId] = useState('');

    const [errorModal, setErrorModal] = useState({
        open: false,
        title: '',
        detail: ''
    });

    const handleErrors = (error) => {
        console.error('error', error);
        const { title, detail } = error.response?.data || {};
        setErrorModal({
            open: true,
            title: title || "Error",
            detail: detail || "An unexpected error occurred."
        });
    };

    useEffect(() => {
        if (isNewApplication) {
            return;
        }
        if (location.state?.application) {
            let application = location.state.application;
            setName(application.name);
            setDescription(application.description);
            setbaseUrl(application.baseUrl);
            setAuthClientId(application.authClientId);
        } else {
            kudconnectApi.getApplication(keycloak.token, applicationId).then((response) => {
                if (response.data) {
                    setName(response.data.name);
                    setDescription(response.data.description);
                    setbaseUrl(response.data.baseUrl);
                    setAuthClientId(response.data.authClientId);
                } else {
                    console.log('No data returned from API');
                }
            });
        }

    }, [location]);


    const onSave = async (event) => {
        event.preventDefault();
        const data = { name, description, baseUrl, authClientId };
        if (isNewApplication) {
            kudconnectApi.saveApplication(keycloak.token, data)
                .then((response) => {
                    history.push('/home');
                })
                .catch((error) => handleErrors(error));
        } else {
            kudconnectApi.updateApplication(keycloak.token, applicationId, data)
                .then((response) => {
                    history.push('/home');
                })
                .catch((error) => handleErrors(error));
        }
    };


    return (
        <Container>
            <Grid centered>
                <Grid.Row>
                    <Segment style={{ width: '330px' }}>
                        <Form onSubmit={onSave}>
                            <Form.Field>
                                <label>Name</label>
                                <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                            </Form.Field>
                            <Form.Field>
                                <label>Description</label>
                                <Input type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
                            </Form.Field>
                            <Form.Field>
                                <label>Login Base URL</label>
                                <Input type="text" value={baseUrl} onChange={(e) => setbaseUrl(e.target.value)} />
                            </Form.Field>
                            <Form.Field>
                                <label>Auth Client ID</label>
                                <Input type="text" value={authClientId} onChange={(e) => setAuthClientId(e.target.value)} />
                            </Form.Field>
                            <Button type="submit">Save</Button>
                        </Form>
                    </Segment>
                </Grid.Row>
            </Grid>
            <ErrorModal
                open={errorModal.open}
                title={errorModal.title}
                detail={errorModal.detail}
                onClose={() => setErrorModal({ open: false, title: '', detail: '' })}
            />
        </Container>
    );
}

export default ApplicationDetail;