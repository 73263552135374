import { useKeycloak } from "@react-keycloak/web";
import { getUsername } from '../misc/Helpers';
import { Container } from 'semantic-ui-react';
import ApplicationList from "../application/ApplicationList";

const Home = () => {
    const { keycloak } = useKeycloak();

    return (
        <Container>
            {keycloak.authenticated && 
            <ApplicationList>Welcome Home {getUsername(keycloak)} </ApplicationList>}
        </Container>
    )
}

export default Home;