import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

function ErrorModal({ open, onClose, title, detail }) {
  return (
    <Modal open={open} onClose={onClose} size="small">
      <Modal.Header>{title || "Error"}</Modal.Header>
      <Modal.Content>
        <p>{detail || "An unexpected error occurred."}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ErrorModal;
