const prod = {
    url: {
        KEYCLOAK_BASE_URL: "http://kudconnect.local:9080",
        API_BASE_URL: 'http://kudconnect.local:8080',
        AVATARS_DICEBEAR_URL: 'https://api.dicebear.com/6.x'
    }
}

const dev_local = {
    url: {
        KEYCLOAK_BASE_URL: "http://localhost:9080",
        API_BASE_URL: 'http://localhost:8080',
        AVATARS_DICEBEAR_URL: 'https://api.dicebear.com/6.x'
    }
}

const dev = {
    url: {
        KEYCLOAK_BASE_URL: "https://accounts.kudconnect.dev.kudostech.ro",
        API_BASE_URL: 'https://api.kudconnect.dev.kudostech.ro',
        AVATARS_DICEBEAR_URL: 'https://api.dicebear.com/6.x'
    }
}

const environments = {
    development_local: dev_local,
    development: dev,
    production: prod
};
export const config = environments[process.env.REACT_APP_ENV] || dev_local;