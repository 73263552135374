import React from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { Route, Redirect } from 'react-router-dom'

function PrivateRoute({ component: Component, roles, ...rest }) {
  const { keycloak } = useKeycloak()
  return (

    <Route
      {...rest}
      render={props => {
        if (keycloak.authenticated) {
          // If roles are defined, check if the user has one of the allowed roles
          if (roles && roles.length && !roles.some(role => keycloak.hasResourceRole(role, 'kudconnect-webapp'))) {
            // User does not have the required roles, redirect to home page or a 403 page
            return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
          }

          // User is authenticated and has the required roles (if any)
          return <Component {...props} />;
        }

        // User is not authenticated, redirect to login page
        return <Redirect to={{ pathname: '/home', state: { from: props.location } }} />;
      }}
    />
  )
}

export default PrivateRoute