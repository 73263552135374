import { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useHistory, useLocation } from "react-router-dom";
import { Table, Button, Container, Icon, Grid } from "semantic-ui-react";
import { kudconnectApi } from "../misc/KudconnectApi";
import ErrorModal from "../misc/ErrorModal";

function ApplicationTable() {
  const [applications, setApplications] = useState([]);
  const { keycloak } = useKeycloak();
  const history = useHistory(); // hook from react-router-dom
  const location = useLocation();

  const [errorModal, setErrorModal] = useState({
    open: false,
    title: "",
    detail: "",
  });

  const handleErrors = (error) => {
    console.error("error", error);
    const { title, detail } = error.response?.data || {};
    setErrorModal({
      open: true,
      title: title || "Error",
      detail: detail || "An unexpected error occurred.",
    });
  };

  const disableApplication = (applications) => {
    const authenticatedUsername = keycloak.tokenParsed.preferred_username;
    applications.forEach((application) => {
      if (authenticatedUsername.includes("cosmin")) {
        if (
          "cosmin@hpm.ro" === authenticatedUsername &&
          application.name === "Helpdesk back office"
        ) {
          application.disabled = false;
        } else if (
          "cosmin.porumb@hypertalk.net" === authenticatedUsername &&
          application.name === "Helpdesk app"
        ) {
          application.disabled = false;
        } else {
          console.log("disable", application.name);
          application.disabled = true;
        }
      }
    });
  };

  const fetchApplications = async () => {
    const response = await kudconnectApi.getApplications(keycloak.token);
    const data = response.data;
    disableApplication(data);
    console.log("data", data);
    setApplications(data);
  };

  useEffect(() => {
    fetchApplications();
  }, [location.pathname]);

  const onEdit = (application) => {
    history.push({
      pathname: "/applicationDetail/" + application.id,
      state: { application },
    });
  };

  const onNewApplication = () => {
    history.push({
      pathname: "/applicationDetail/new",
    });
  };

  const onRedirect = (application) => {
    kudconnectApi
      .getApplicationRedirectUrl(keycloak.token, application.id)
      .then((response) => {
        const redirectUrl = response.data.redirectUrl;
        console.log("redirect-url", redirectUrl);
        window.open(redirectUrl, "_blank");
      });
  };

  const onDelete = async (application) => {
    kudconnectApi
      .deleteApplication(keycloak.token, application.id)
      .then((response) => {
        fetchApplications();
      })
      .catch((response) => {
        handleErrors(response);
      });
  };

  return (
    <Container>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Base URL</Table.HeaderCell>
            <Table.HeaderCell>Auth Client ID</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {applications.map((application) => (
            <Table.Row key={application.id} disabled={application.disabled}>
              <Table.Cell>{application.name}</Table.Cell>
              <Table.Cell>{application.description}</Table.Cell>
              <Table.Cell>{application.baseUrl}</Table.Cell>
              <Table.Cell>{application.authClientId}</Table.Cell>
              <Table.Cell>
                {keycloak.authenticated &&
                  keycloak.hasResourceRole("admin", "kudconnect-webapp") && (
                    <>
                      <Button icon onClick={() => onEdit(application)}>
                        <Icon name="edit" />
                      </Button>
                      <Button icon onClick={() => onDelete(application)}>
                        <Icon name="delete" />
                      </Button>
                    </>
                  )}
                <Button icon onClick={() => onRedirect(application)}>
                  <Icon name="world" />
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Grid centered columns={2}>
        <Grid.Column>
          <Button primary icon labelPosition="right" onClick={onNewApplication}>
            New Application
            <Icon name="plus" />
          </Button>
        </Grid.Column>
      </Grid>
      <ErrorModal
        open={errorModal.open}
        title={errorModal.title}
        detail={errorModal.detail}
        onClose={() => setErrorModal({ open: false, title: "", detail: "" })}
      />
    </Container>
  );
}

export default ApplicationTable;
