import axios from "axios";
import { config } from "../../Constants";

export const kudconnectApi = {
  saveUserDetails,
  getUserDetails,
  getApplications,
  getApplication,
  updateApplication,
  saveApplication,
  deleteApplication,
  getApplicationRedirectUrl
};

const instance = axios.create({
  baseURL: config.url.API_BASE_URL,
});

function bearerAuth(token) {
  return `Bearer ${token}`;
}

function getUserDetails(token) {
  return instance.get(`/v1/user-profile/me`, {
    headers: { Authorization: bearerAuth(token) },
  });
}

function saveUserDetails(token, userDetails) {
  return instance.put(`/v1/user-profile/me`, userDetails, {
    headers: { Authorization: bearerAuth(token) },
  });
}

function getApplications(token) {
  return instance.get(`/v1/applications`, {
    headers: { Authorization: bearerAuth(token) },
  });
}

function getApplication(token, applicationId) {
  return instance.get(`/v1/applications/${applicationId}`, {
    headers: { Authorization: bearerAuth(token) },
  });
}

function saveApplication(token, data) {
  return instance.post(`/v1/applications`, data, {
    headers: { Authorization: bearerAuth(token) },
  });
}

function updateApplication(token, applicationId, data) {
  return instance.put(`/v1/applications/${applicationId}`, data, {
    headers: { Authorization: bearerAuth(token) },
  });
}

function deleteApplication(token, applicationId) {
  return instance.delete(`/v1/applications/${applicationId}`, {
    headers: { Authorization: bearerAuth(token) },
  });
}

function getApplicationRedirectUrl(token, applicationId) {
  return instance.get(`/v1/applications/${applicationId}/redirect-url`, {
    headers: { Authorization: bearerAuth(token) },
  });
}

